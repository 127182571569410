header {
  background: $color-background-light;
  box-shadow: 0 0 30px 0 $header-shadow;
  left: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 200;
}

header .container {
  align-items: center;
  display: flex;
  height: 84px;
}

.header-locale {
  @include font-primary('condensed');

  flex: 1 1 0;
  font-size: 14px;
  margin-bottom: 0px;
  text-align: right;
}

.header-logo {
  height: calc(84px - #{spacer(4)});
  width: auto;
}