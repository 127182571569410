.pb-15px,
.py-15px {
  padding-bottom: 15px;
}

.pt-15px,
.py-15px {
  padding-top: 15px;
}

.pl-15px,
.px-15px {
  padding-left: 15px;
}

.pr-15px,
.px-15px {
  padding-right: 15px;
}
