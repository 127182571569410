.form-multiselect {}

.form-multiselect:hover *[class*='-control'] {
  box-shadow: inset 0 0 4px $color-black;
}

.form-multiselect *[class*='-control'] {
  background-color: transparent;
  border: 1px solid $color-gray-dark;
  border-radius: var(--border-radius);
  box-shadow: none;
  color: $form-multiselect-color;
  font-size: 12px;
  padding: 1px 0px;

  &:hover {
    border: 1px solid $color-gray-dark;
  }
}

.form-multiselect *[class*='-control'] > * {
  padding: 0px 6px;
}

.form-multiselect *[class*='-indicatorContainer'] {
  color: $color-gray-dark;
  cursor: pointer;

  &:hover {
    color: $color-gray-dark;
  }
}

.form-multiselect *[class*='-indicatorSeparator'] {
  background-color: $color-gray-dark;
}

.form-multiselect *[class*='-Input'] input[type='text'] {
  border: none;
  border-radius: 0px;

  &:focus {
    box-shadow: none;
  }
}

.form-multiselect *[class*='-menu'] {
  border: 1px solid var(--brand-primary);
  border-radius: var(--border-radius);
  overflow: hidden;
  z-index: 101;
}

.form-multiselect *[class*='-MenuList'] {
  border-radius: var(--border-radius);
}

.form-multiselect *[class*='-multiValue'] {
  border-radius: calc(var(--border-radius) * 0.75);
  font-size: 12px;
}

.form-multiselect *[class*='-multiValue'] > * {
  font-size: 12px;
}

.form-multiselect *[class*='-multiValue'] > *:first-child {
  padding: 0px 3px 0px 6px;
}

.form-multiselect *[class*='-multiValue'] > *:last-child:hover {
  background-color: var(--button-hover);
  border-radius: calc(var(--border-radius) * 0.75);
  color: $color-white;
  cursor: pointer;
}

.form-multiselect *[class*='-option'] {
  background-color: transparent;
  color: $form-multiselect-color;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    background-color: $color-gray-extralight;
  }
}

.form-multiselect *[class*='-placeholder'] {
  color: $form-multiselect-color;
}

// State: Disabled

.isDisabled .form-multiselect {
  background-color: $form-disabled;
}

// State: Disabled

.is-invalid .form-multiselect *[class*='-control'] {
  border-color: $color-error;
}
