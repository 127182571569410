/*
 *  Font Display
 *
 */
@mixin font-display($var: '') {
  font-family: var(--font-family), 'Helvetica Neue LT W05_55 Roman', Helvetica, sans-serif;

  @if $var == 'bold' {
    font-family: var(--font-family), 'Helvetica Neue LT W05_55 Roman', Helvetica, sans-serif;
  }
}

/*
 *  Font-face Based on http://codepen.io/javasteve99/pen/hDxpn
 *
 */

 @mixin font-face($font-family, $file-path, $font-weight: normal, $font-style: normal) {
  @font-face {
    font-family: $font-family;
    src: url("#{$file-path}.eot");
    src: url("#{$file-path}.eot?#iefix") format("embedded-opentype"), url("#{$file-path}.woff2") format("woff2"), url("#{$file-path}.woff") format("woff"), url("#{$file-path}.ttf") format("truetype"), url("#{$file-path}.svg##{$font-family}") format("svg");
    font-weight: $font-weight;
    font-style: $font-style;
  }

  // Chrome for Windows rendering fix: http://www.adtrak.co.uk/blog/font-face-chrome-rendering/
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
      font-family: $font-family;
      src: url("#{$file-path}.svg##{$font-family}") format("svg");
    }
  }
}

/*
 *  Font Smoothing 
 *
 */

 @mixin font-smoothing {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

/*
 *  Input Focus
 *
 */
@mixin input-focus {
  &:focus {
    outline: none;
    border-color: $color-link;
    box-shadow: inset 0 0 4px $color-link;
  }
}

/*
 *  Input Focus Error
 *
 */
@mixin input-focus-error {
  &:focus {
    border-color: $color-error;
    box-shadow: inset 0 0 4px $color-link;
  }
}

/*
  Spacer Generator
  ---
  Output a list of spacers with a consistent multiplier
  to standardize spacing of margins, padding, etc.
  -
  Usage
  .selector {
    margin-bottom: spacer(2);
    padding: spacer(1) spacer(3);
  }
*/

@function spacer($multiplier) {
  @return $multiplier * $spacer-size;
}